.about__image {
  margin: 50px auto;
  max-width: 800px;
  text-align: center; /* Center the image */
}

.about__image img {
  width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  max-height: 400px; /* Limit height */
  object-fit: cover; /* Cover the area without distortion */
}

.about__story-text {
  margin: 50px auto;
  max-width: 600px;
  text-align: center;
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.about__story-text h5 {
  font-weight: 700;
  line-height: 1.4;
  font-size: 1.2rem;
  color: #1e2b37;
}

.about__story-text p {
  padding-top: 20px;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 20px 0;
  color: #333;
  text-align: justify;
}

.features-section {
  margin-top: 40px;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  text-align: left;
}

.feature-icon {
  font-size: 40px;
  color: #0056b3;
  margin-right: 15px;
}

.feature-content {
  display: flex;
  flex-direction: column;
}

.features-section h6 {
  font-weight: 600;
  margin: 0;
  font-size: 1.1rem;
}

.features-section p {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0 0;
}

@media (max-width: 768px) {
  .about__image,
  .about__story-text {
    margin: 30px auto;
  }

  .about__story-text h5 {
    font-size: 1.5rem;
  }

  .about__story-text p {
    font-size: 1rem;
  }

  .feature {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .feature-icon {
    margin-bottom: 10px;
    font-size: 30px;
  }

  .features-section h6 {
    font-size: 1rem;
  }

  .features-section p {
    font-size: 0.85rem;
  }
}
