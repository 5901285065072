.contact__information {
  text-align: center;
  padding-top: 50px;
}
.contact__information p {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 1rem;
}

.contact__details {
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;
  height: 220px;
  border-radius: 3px;
  border: 1px solid #9a9aa236;
  margin-bottom: 70px;
}
.contact-button {
  margin-bottom: 50px;
}
