.about-us {
  padding: 2rem;
  background-color: #f9f9f9;
}

.about-us-title {
  text-align: center;
  margin-bottom: 1rem;
}

.about-us-paragraph {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 15px;
  text-align: center;
  max-width: 95rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}
.about-us-image {
  border-radius: 50%;
  width: 600px;
  height: 600px;
  object-fit: cover;
  margin-left: 200px;
}

.image-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 500px;
}

.icon-item {
  display: flex;
  align-items: flex-start;
  padding-top: 60px;
  margin-bottom: 1rem;
  justify-content: flex-start;
}

.icon-content {
  display: flex;
  align-items: center;
}

.icon-content i {
  font-size: 2rem;
  margin-right: 10px;
  color: #0056b3;
}

.icon-text {
  display: flex;
  flex-direction: column;
}

.icon-item h5 {
  margin: 0;
  font-size: 1.3rem;
}

.icon-item p {
  margin: 0;
  font-size: 0.9rem;
}

.row {
  display: flex;
}

@media (max-width: 768px) {
  .about-us-image {
    width: 300px;
    height: 400px;
    border-radius: 50%;
  }
}
@media (max-width: 768px) {
  .about-us-image {
    width: 80%; /* Make the image smaller on mobile */
    margin: 0 auto; /* Center the image */
    border-radius: 50%;
  }

  .icon-column {
    padding-left: 0; /* Remove left padding on mobile */
  }

  .icon-item {
    padding-top: 20px; /* Less padding for mobile */
  }
}

@media (max-width: 576px) {
  .about-us-title {
    font-size: 1.8rem; /* Adjust title size for smaller screens */
  }

  .about-us-paragraph {
    font-size: 1rem; /* Smaller text for mobile */
  }

  .icon-item h5 {
    font-size: 1.2rem; /* Smaller title size */
  }

  .icon-item p {
    font-size: 0.8rem; /* Smaller paragraph size */
  }
}
