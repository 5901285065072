* {
  box-sizing: border-box;
}

main {
  min-height: 80vh;
}
/* PROJECTSPAGE */

.hover-effect {
  transition: all 0.5s ease-in-out;
}

.hover-effect:hover {
  transform: scale(1.03);
  transition: all 0.5s ease-in-out;
  box-shadow: 5px 5px 10px #b3a8a8, -5px -5px 10px #f2e4e4;
  opacity: 1;
}
.img-card {
  width: 100%; /* Stretches the image across the screen */
  height: auto; /* Maintains aspect ratio */
  max-height: 350px; /* Sets a maximum height */
  min-height: 250px; /* Sets a minimum height */
  object-fit: cover;
  display: block;
}

.img-card-small {
  height: 300px;
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  cursor: pointer;
  object-fit: cover;
}
.project__header {
  margin-bottom: 30px;
}

.lead-image {
  padding-left: 200px;
  object-fit: cover;
}

/* --------- */
/* Modal */

.modal__container {
  max-width: 1000px;
}

.modal__image {
  background: no-repeat center center fixed;
  height: 80vh;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}

/* SERVICESPAGE */
.img-rounded {
  max-height: 300px;
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  margin-left: 30px;
}
.image-rounded-title {
  font-size: 1.5rem;
  padding-left: 30px;
}
.service__quality-list {
  list-style-type: circle;
  font-size: 1.1rem;
  line-height: 1.5;
}

.image__header {
  background: radial-gradient(#0056b3, rgb(152, 152, 239));
  background-size: 100% 200%;
  background-position: 50% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-size: 3.5rem;
  margin-bottom: 30px;
}
.image__view__gallery {
  background: radial-gradient(#0056b3, rgb(152, 152, 239));
  background-size: 100% 200%;
  background-position: 50% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 30px;
}

/* MEDIA-QUERIES */

/* @media only screen and (min-width: 1200px) {
} */
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  /* footer */
  .designed {
    text-align: end;
    margin-left: 80px;
  }
  /* Reusuable css */
  .paragraph__text {
    font-size: 1rem;
    text-align: center;
  }
  /* services */
  .img-rounded {
    max-height: 250px;
    min-height: 200px;
    margin-left: 30px;
  }
  .image-rounded-title {
    padding-left: 30px;
    font-size: 1.2rem;
  }
  /* aboutpage */
  .about__story-text h4 {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.4;
  }
  .about__story-text p {
    font-size: 1rem;
  }
  /* homepage */
  .slider__title {
    font-size: 4rem;
  }
  .section__title h1 {
    font-size: 2.5rem;
  }
  .section__title h4 {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1023px) {
  /* footer  */

  .container__footer {
    margin: 0 auto;
  }
  .footer__social-info {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0;
  }
  .footer__social-info span {
    font-size: 1.7rem;
    padding: 0;
  }
  .designed {
    margin: 0;
  }
  /* homepage */
  .card-text {
    font-size: 0.8rem;
  }
  .gallery__title {
    font-size: 1.7rem;
  }
  .slider__title {
    font-size: 3.7rem;
  }
  .section__title h1 {
    font-size: 2.3rem;
  }
  .section__title h4 {
    font-size: 1.1rem;
  }
  /* services   */
  .img-rounded {
    min-height: 240px;
    min-height: 190px;
    margin: 3px;
  }
  .image-rounded-title {
    font-size: 1.2rem;
    padding-right: 30px;
  }
  /* reusablecss */
  .paragraph__text {
    font-size: 1rem;
  }
  .service__quality-list {
    font-size: 0.9rem;
  }
  .about__image {
    height: 500px;
  }
  .about__image img {
    object-fit: cover;
  }
  .contact__details-info li {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  /* homepage */
  .slider__title {
    font-size: 3.65rem;
  }
  .section__title h1 {
    font-size: 2.2rem;
  }
  .section__title h4 {
    font-size: 1.1rem;
  }
  .card-text {
    font-size: 0.82rem;
  }
  .gallery__title {
    font-size: 1.5rem;
  }
  .btn__reviews {
    margin-bottom: 10px;
  }
  /* footer  */
  .footer__social-info {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0;
  }
  .footer__social-info span {
    font-size: 1.7rem;
    padding: 0;
  }
  .designed {
    margin: 0;
    font-size: 0.8rem;
  }
  .contact__details-info li {
    font-size: 0.9rem;
  }
  /* aboutpage */
  .about__story-text h4 {
    font-size: 1rem;
    line-height: 1.5;
  }
  .about__story-text p {
    font-size: 0.9rem;
  }
  .about__image {
    height: 400px;
  }
  .about__image img {
    object-fit: cover;
  }
  /* Reusuable css */
  .paragraph__text {
    font-size: 1rem;
    text-align: center;
  }
  .paragraph__text-pad {
    font-size: 1rem;
    padding: 30px 0 !important;
  }
  .image-rounded-title {
    font-size: 1.1rem;
  }
  .service__quality-list {
    font-size: 0.9rem;
  }
  .modal__container {
    max-width: 700px;
  }
  .modal__image {
    height: 70vh;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* homepage */
  .slider__title {
    font-size: 3.5rem;
  }

  .card-text {
    font-size: 1.1rem;
  }

  .btn__reviews {
    margin-bottom: 30px;
  }

  .services__text p {
    font-size: 1.2rem;
    padding: 20px 70px;
  }
  .services__text h1 {
    font-size: 2.5rem;
  }
  .gallery__title {
    font-size: 2em;
  }

  .gallery {
    grid-gap: 5px;
    grid-template-rows: repeat(5, 10vw);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  /* ABOUT-PAGE */
  .about__image {
    margin: 0 auto;
  }

  .about__story-text {
    margin: 40px auto;
    padding: 0 20px;
  }
  .col-responsive {
    display: flex;
    flex-direction: column;
  }

  /* PROJECTS */
  .project__header {
    text-align: center;
    margin-top: 30px;
  }
  /* SERVICES */

  .image-rounded-title {
    padding-left: 30px;
  }

  /* reusable */
  .paragraph__text {
    font-size: 0.95rem;
    text-align: center;
  }
  .service__quality-list {
    font-size: 0.9rem;
  }

  .image__header {
    font-size: 2.1rem;
  }

  /* CONTACT PAGE */
  .contact__container {
    width: 100%;
  }

  .contact__details {
    width: 100%;
  }
  .logo {
    /* height: 70px; */
    /* width: 30px; */
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  /* homepage */

  .slider__btn {
    font-size: 15px;
    width: 60%;
  }
  .slider__title {
    font-size: 2.5rem;
  }
  .gallery {
    grid-gap: 5px;
    grid-template-rows: repeat(5, 10vw);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card-text {
    font-size: 0.9rem;
  }

  .section__title h1 {
    font-size: 2.3rem;
  }
  .section__title h4 {
    font-size: 1.1rem;
  }

  /* footer */
  .container__footer {
    margin: 0 auto;
    text-align: center;
  }
  .footer__social-info {
    align-items: center;
    justify-content: space-around;
    margin-top: 0;
  }
  .footer__social-info span {
    font-size: 1.7rem;
  }
  .designed {
    margin: 0;
  }
  /* About  */

  .about__story-text {
    margin: 40px auto;
  }
  .about__story-text h4 {
    font-size: 1rem;
    line-height: 1.5;
  }
  .about__story-text p {
    font-size: 0.9rem;
  }
  .about__image {
    margin: 0 auto;
  }
  .image__header {
    font-size: 2rem;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .paragraph__text {
    font-size: 1rem;
    padding: 50px 10px !important;
  }
  .image-rounded-title {
    font-size: 1rem;
    padding-bottom: 30px;
  }
  .contact__container {
    width: 100%;
  }

  .contact__details {
    width: 100%;
  }
  .contact_logo {
    display: none;
  }
  .contact__information h2 {
    font-size: 1.6rem;
  }
  .paragraph__text {
    font-size: 1rem;
    padding: 10px 0 !important;
  }
  .service__quality-list {
    font-size: 0.8rem;
  }
  .service__subheader {
    font-size: 1.3rem;
  }
  .logo {
    /* height: 70px; */
  }
  .img-card-small {
    height: 150px;
    min-height: 150px;
  }
}

/* REUSABLE */

.slider__btn {
  letter-spacing: 0.1em;
  cursor: pointer;
  font-size: 18px; /* Adjust font size */
  font-weight: 400;
  line-height: 45px;
  max-width: 300px;
  height: 50px;
  margin-top: 20px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}

.slider__btn:hover {
  text-decoration: none;
}

.slider__btn-effect {
  color: #fff;
  padding-top: 2px;
  border: 4px solid transparent;
  background-color: #1e2b37;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.slider__btn-effect:hover {
  background-color: #0056b3;
  color: #fff;
}

/* btn_text */
.slider__btn-effect button {
  transition: all 0.2s ease-out;
  z-index: 2;
}

.slider__btn-effect:hover button {
  letter-spacing: 0.13em;
  color: black;
}

/* highlight */
.slider__btn-effect:after {
  background: #0056b3;
  border: 0px solid #000;
  content: '';
  height: 155px;
  left: -75px;
  opacity: 0.8;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  width: 50px;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 1;
}

.slider__btn-effect:hover:after {
  background: #0056b3;
  border: 20px solid #000;
  opacity: 0;
  left: 120%;
  transform: rotate(40deg);
}

.slider__btn:hover {
  text-decoration: none;
}
