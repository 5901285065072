.logo-footer {
  height: 35px;
}

.footer {
  background-color: #ffffff;
  color: #000000;
  padding: 2rem;
  border-top: 1px solid #e0e0e0;
}

.footer__social-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.footer__social-info span {
  font-size: 2rem;
  padding: 0 15px;
  transition: transform 0.3s;
}

.footer__social-info span:hover {
  transform: scale(1.1);
}

.contact__details-info {
  list-style: none;
  padding: 0;
  /* margin: 20px 0; */
}

.contact__details-info li {
  margin: 10px 0;
}

.contact__details-info a {
  color: #000000;
  text-decoration: none;
}

.contact__details-info a:hover {
  text-decoration: underline;
  color: #000000;
}

.designed {
  text-align: center;
  margin-top: 20px;
  color: black !important;
  font-size: 0.9rem;
}

.fa-linkedin,
.fa-facebook-square,
.fa-yelp,
.fa-instagram,
.fa-youtube,
.fa-google {
  transition: color 0.3s ease, transform 0.3s;
}

.fa-linkedin {
  color: #0e76a8;
}

.fa-linkedin:hover {
  color: #0c5c82;
}

.fa-facebook-square {
  color: #3b5998;
}

.fa-facebook-square:hover {
  color: #2e4b72;
}

.fa-yelp {
  color: #c41200;
}

.fa-yelp:hover {
  color: #8c1104;
}

.fa-instagram {
  color: #e4405f;
  transition: all 0.5s ease;
}

.fa-instagram:hover {
  filter: brightness(1.2);
}

.fa-youtube {
  color: #ff0000;
}

.fa-youtube:hover {
  color: #ff0000;
  transform: scale(1.1);
}
.fa-google {
  color: green;
  transition: all 0.5s ease;
}

.fa-google:hover {
  color: blue;
  filter: brightness(1.2);
}

@media (max-width: 768px) {
  .footer {
    padding: 1rem;
  }

  .footer__social-info {
    flex-wrap: wrap;
  }
}
